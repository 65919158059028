.dialog .text-transform-none {
  text-transform: none;
}

.dialog .dialog__action-button{
  font-size: 11px;
  height: 24px;
  padding: 0 10px;
  font-family: 'Roboto';
}

.small-width-dialog .MuiDialog-paper {
  max-width: 444px;
}

.dialog .MuiAlert-message {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.dialog .MuiAlert-message div {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.dialog .dialog__linear-timer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}