@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./roboto-v30-cyrillic_latin-300.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./roboto-v30-cyrillic_latin-regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./roboto-v30-cyrillic_latin-500.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./roboto-v30-cyrillic_latin-700.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('./roboto-v30-cyrillic_latin-900.woff2') format('woff2');
}
