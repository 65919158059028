@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Bold'), url('OpenSans-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Bold Italic'), url('OpenSans-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans ExtraBold'), url('OpenSans-ExtraBold.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans ExtraBold Italic'), url('OpenSans-ExtraBoldItalic.ttf') format('ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Italic'), url('OpenSans-Italic.ttf') format('ttf');
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Light'), url('OpenSans-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Light Italic'), url('OpenSans-LightItalic.ttf') format('ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Medium'), url('OpenSans-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Medium Italic'), url('OpenSans-MediumItalic.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans Regular'), url('OpenSans-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans SemiBola'), url('OpenSans-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans", sans-serif;
  src: local('Open Sans SemiBola Italic'), url('OpenSans-SemiBoldItalic.ttf') format('ttf');
  font-weight: 600;
  font-style: italic;
}