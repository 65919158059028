.console__menu {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 8px 0 8px;
    background-color: #F6F3F5;
    border-radius: 4px;
    gap: 8px;
}

.console__menu .menu__title {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15px;
}

.console__menu .menu__button svg {
    width: 16px;
    height: 16px;
}

.console__menu .menu__start-button {
    margin-left: auto;
    width: 65px;
    height: 24px;
    background-color: var(--buttons-primary);
    text-transform: none;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.46px;
    color: #33383e;
}

.console__menu .menu__interview-buttons {
    margin-left: auto;
}

.console__menu .text-transform-none {
    text-transform: none;
}

.console__menu .menu__interview-button{
    font-size: 11px;
    height: 24px;
    padding: 0 10px;
    font-family: 'Roboto';
}

.console__menu .menu__start-button:hover {
    background-color: #4caf50;
}

.console__menu .console__tabs{
    min-height: auto;
    height: 100%;
    max-height: 32px;
}

.console__menu .MuiTabs-flexContainer{
    height: 100%;
    gap: 8px;
}

.console__menu .console__tab{
    min-height: auto;
    color: #0E1525;
    opacity: 0.6;
    padding: 0;
    font-size: 12px;
    font-family: 'Roboto';
}

.console__menu .console__tab:first-of-type::after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.12);
    margin-left: 8px;
}

.console__menu .MuiTab-root {
    text-transform: none;
}

.console__menu .MuiTabs-indicator{
    display: none;
}

.console__menu .console__tabs .Mui-selected{
    color: #0E1525;
    opacity: 1;
    cursor: default;
}

.console__menu .menu__button {
    padding: 0;
}

.console__menu .menu__button-icon{
    width: 20px;
    height: 20px;
}