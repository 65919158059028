@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Bold'), url('JetBrainsMono-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Bold Italic'), url('JetBrainsMono-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono ExtraBold'), url('JetBrainsMono-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono ExtraBold Italic'), url('JetBrainsMono-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono ExtraLight'), url('JetBrainsMono-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono ExtraLight Italic'), url('JetBrainsMono-ExtraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Italic'), url('JetBrainsMono-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Light'), url('JetBrainsMono-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Light Italic'), url('JetBrainsMono-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Medium'), url('JetBrainsMono-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Medium Italic'), url('JetBrainsMono-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Regular'), url('JetBrainsMono-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono SemiBold'), url('JetBrainsMono-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono SemiBold Italic'), url('JetBrainsMono-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Thin'), url('JetBrainsMono-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono Thin Italic'), url('JetBrainsMono-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}
