.editor-page__editor .editor__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background-color: var(--background-primary);
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
}

.editor-page__editor .editor__header .header__button {
    padding: 0;
    animation: none;
}

.editor-page__editor .editor__header .header__button .header__icon,
.editor-page__editor .editor__header .header__button .header__icon_active {
    width: 16px;
    height: 16px;
}

.editor-page__editor .editor__header .header__button .header__icon_active {
    color: #4caf50;
}

.editor-page__editor .editor__header .header__select {
    min-width: 152px;
    max-height: 24px;
}

.select .select__icon-container {
    width: 16px;
    height: 16px;
    min-width: 0px;
    margin-right: 8px;
}

.select .select__icon-container .select__icon {
    width: 16px;
    height: 16px;
}
