.editor-page__header {
    display: flex;
    padding: 0 16px 0;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-primary);
    grid-column: 1 / -1;
}

.editor-page__header .header__logo {
    width: 88px;
    height: 24px;
}

.editor-page__header .header__content {
    display: flex;
    gap: 16px;
}

.editor-page__header .header__buttons {
    display: flex;
    gap: 16px;
}

.editor-page__header .header__button {
    padding: 0;
}

.editor-page__header .header__button--without-effects {
    cursor: default;
}

.editor-page__header .header__avatars {
    display: flex;
}

.editor-page__header .header__avatar {
    width: 24px;
    height: 24px;
}

.editor-page__header .header__avatar:not(:first-child) {
    margin-left: -8px;
}

.editor-page__header .header__text{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.editor-page__header .header__button .header__alarm-info {
    align-items: center;
}

.editor-page__header .header__button .header__icon {
    width: 1.5rem;
    height: 1.5rem;
}