.editor-page__console {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 16px);
}

.editor-page--horizontal .editor-page__console {
    height: 100%;
}

.editor-page__console .console__content-wrapper {
    position: relative;
    flex-grow: 1;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
}

.editor-page__console .console__content {
    height: 100%;
    font-weight: normal;
    font-size: 14px;
    font-feature-settings: 'liga' 0, 'calt' 0;
    line-height: 18px;
    letter-spacing: 0px;
    white-space: pre;
    overflow: scroll;
}

.editor-page__console .console__content-with-tasks {
    padding: 12px 32px;
}

.editor-page__console .console__content-without-tasks {
    padding: 12px 20px;
    font-family: 'JetBrains Mono', 'Courier New', monospace;
}

.editor-page__console .console__tests-wrapper {
    display: grid;
    gap: 16px;
}

.editor-page__console .console__results-wrapper {
    display: grid;
    gap: 8px;
}

.editor-page__console .console__tests-result {
    display: flex;
    align-items: center;
    gap: 4px;
}

.editor-page__console .console__content .console__results-text {
    font-family: "Fira Code", monospace;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.editor-page__console .console__tests-result-icon {
    width: 16px;
    height: 16px;
}
