.task-description .task-description__header{
  width: 100%;
  display: flex;
  background-color: var(--background-primary);
  height: 40px;
  border-radius: 4px 4px 0 0;
}

.task-description .task-description__header-title{
  padding: 8px 16px;
  box-sizing: border-box;
  color: #4CAF50;
  border-bottom: 2px solid #4CAF50;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';
  line-height: 24px;
}