.info-dialog__wrapper .info-dialog__content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'Open Sans', sans-serif;
}

.info-dialog__wrapper .info-dialog__content .info-dialog__list {
  margin-left: 15px;
}

.info-dialog__wrapper .info-dialog__content .info-dialog__text {
  font-family: "Open Sans", sans-serif;
}