.editor-page__editor {
    margin: auto;
    height: 100%;
    display: grid;
    grid-template-rows: 40px auto;
    flex-direction: column;
    overflow: auto;
}

.editor--without-tasks{
    width: calc(100% - 16px);
}

.editor--with-tasks{
    margin-right: 0;
    padding-right: 8px;
    flex-grow: 1;
    min-width: 500px;
}