.editor-page .ScrollbarsCustom-Wrapper {
  inset: 0 0 0 0;
  border-radius: 0 0 4px 4px;
}

.editor-page .ScrollbarsCustom-TrackY {
  background-color: #fff;
  border-left: 1px solid #EBEBEB;
  position: absolute;
  right: 0;
  width: 14px;
  height: 100%;
  border-radius: 0 0 4px 0;
}

.editor-page .ScrollbarsCustom .ScrollbarsCustom-ThumbY {
  background-color: #cdcdcd;
  width: 100%;
  opacity: 0;
  transition: opacity 800ms linear;
}

.editor-page .ScrollbarsCustom .ScrollbarsCustom-ThumbY:hover {
  background-color: var(--scrollbar-hover);
  opacity: 1;
}

.editor-page .ScrollbarsCustom .ScrollbarsCustom-ThumbY:active {
  background-color: #4b4b4b;
  opacity: 1;
}

.editor-page .ScrollbarsCustom:hover .ScrollbarsCustom-ThumbY {
  transition: opacity 100ms linear;
}

.editor-page .ScrollbarsCustom:hover .ScrollbarsCustom-ThumbY {
  opacity: 1;
}

.editor-page .ScrollbarsCustom-Content {
  display: grid;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  min-height: 100%;
  min-width: 100%;
}

.editor-page .description__content-box {
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  height: 100%;
}

.editor-page .description__content {
  box-sizing: content-box;
  overflow: auto;
  margin: 8px 32px 0px;
  padding-bottom: 32px;
  width: auto;
}

.editor-page .description__grid-item {
  padding: 0;
}

.editor-page .description__title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.editor-page .description__content .description__title-grid-item {
  padding-top: 0;
}

.editor-page .description__content .description__text {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.editor-page .description__content .description__text--bold {
  font-weight: 600;
}

.editor-page .description__text-box {
  background-color: #EBEBEB;
  padding: 4px 8px;
}