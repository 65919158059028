.editor-page__editor .console-editor__root {
    overflow: auto;
    z-index: 2;
}

.editor-page__editor .console-editor__root > div {
    z-index: 2;
}

.editor-page__console .monaco-editor .margin-view-overlays {
    width: 32px !important; 
}

.editor-page__console .monaco-editor .current-line {
    width: auto !important; 
}

.editor-page__console .monaco-editor .line-numbers{
    width: auto !important;
}

.editor-page__console .monaco-editor .vs {
    left: 32px !important;
}

.editor-page__console .monaco-editor .decorationsOverviewRuler {
    visibility: hidden;
}