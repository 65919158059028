@import './assets/fonts/fonts.css';

:root {
    --background-primary: #f6f3f5;
    --buttons-primary: #81c784;

    --scrollbar-main: rgba(121, 121, 121, 0.4);
    --scrollbar-hover: rgba(100, 100, 100, 0.7);
    --scrollbar-active: rgba(191, 191, 191, 0.4);
}

*,
*::after,
*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

* {
    scrollbar-width: auto;
    scrollbar-color: var(--scrollbar-main) transparent;
}

*::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar-corner {
    background: transparent;
    border-left: 1px solid var(--scrollbar-main);
    border-top: 1px solid var(--scrollbar-main);
}

*::-webkit-scrollbar-track:vertical {
    border-top: none;
}

*::-webkit-scrollbar-track:horizontal {
    border-left: none;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-main);
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-hover);
}

*::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-active);
}

body {
    overflow: hidden;
}

#root {
    height: 100%;
}

.monaco-remote-cursor {
    position: absolute;
    pointer-events: none;
    z-index: 4000;
    width: 2px;
}

.monaco-remote-cursor:before {
    content: '';
    width: 6px;
    height: 5px;
    display: block;
    margin-left: -2px;
    margin-top: 0;
    z-index: 4000;
    background: inherit;
}

.monaco-remote-cursor-tooltip {
    position: absolute;
    white-space: nowrap;
    color: #ffffff;
    text-shadow: 0 0 1px #000000;
    opacity: 1;
    font-size: 12px;
    padding: 2px;
    font-family: sans-serif;
    z-index: 4000;
    top: -22px !important;

    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
}

.monaco-remote-selection {
    position: absolute;
    pointer-events: auto;
    opacity: 0.3;
    background: blue;
}