@font-face {
    font-family: "Fira Code", monospace;
    src: local('Fira Code Bold'), url('FiraCode-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Fira Code", monospace;
    src: local('Fira Code SemiBold'), url('FiraCode-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Fira Code", monospace;
    src: local('Fira Code Medium'), url('FiraCode-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Fira Code", monospace;
    src: local('Fira Code Regular'), url('FiraCode-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Fira Code", monospace;
    src: local('Fira Code Light'), url('FiraCode-Light.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}
