.editor-page .task-description {
  flex-grow: 1;
  padding: 0 8px;
  height: 100%;
  overflow: hidden;
  position: relative
}

.editor-page .task-description__content-wrapper{
  position: relative;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.editor-page .task-description__content-wrapper p{
  font-family: "Open Sans", sans-serif;
}