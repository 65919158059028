.editor-page {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    display: grid;
    grid-template-rows: 48px auto auto;
    gap: 8px;
}

.editor-page--horizontal {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 48px auto;
    gap: 8px 0px;
}

.editor-page__task-section{
    display: flex;
    overflow: hidden;
}

.editor-page__main-content{
    overflow: auto;
}