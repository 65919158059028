.editor-page__editor .editor__statusbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    padding: 0 9px;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
}

.editor-page__editor .editor__statusbar .statusbar__text {
    height: 100%;
    padding: 0 6px;
}
